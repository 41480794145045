<template>
    <div class="container">
        <div class="header">
            <div class="page-title">心理健康服务平台</div>
            <div class="date-time">{{today}}</div>
            <div class="week">{{week}}</div>
            <div class="index" @click="goIndex">进入概览</div>
        </div>
        <div class="main">
            <div class="index-day">
                <div class="index-box">
                    <div class="index-title">今日情绪指数总览</div>
                    <div class="chart-box">
                        <div class="chart" id="emotion-chart"></div>
                    </div>
                </div>
                <div class="index-box">
                    <div class="index-title">今日压力指数总览</div>
                    <div class="chart-box">
                        <div class="chart" id="stress-chart"></div>
                    </div>
                </div>
                <div class="index-box">
                    <div class="index-title">今日疲劳指数总览</div>
                    <div class="chart-box">
                        <div class="chart" id="tired-chart"></div>
                    </div>
                </div>
            </div>
            <div class="view-mid">
                <div class="identify">
                    <div class="identify-item">
                        <div class="identify-title">情绪占比</div>
                        <div class="identify-chart">
                            <div id="identify-emotion"></div>
                        </div>
                    </div>
                    <div class="identify-item">
                        <div class="identify-title">压力占比</div>
                        <div class="identify-chart">
                            <div id="identify-stress"></div>
                        </div>
                    </div>
                    <div class="identify-item">
                        <div class="identify-title">疲劳占比</div>
                        <div class="identify-chart">
                            <div id="identify-tired"></div>
                        </div>
                    </div>
                </div>                
                <div class="index-hour">
                    <div class="hour-item">
                        <div class="hour-title">总体情绪指数</div>
                        <div class="icn-container" v-if="emotion_mean!='0'">
                            <img src="../assets/images/emotion1.png" alt="" class="index-pic" v-if="formatIcn(emotion_mean,'emotion')==3">
                            <img src="../assets/images/emotion2.png" alt="" class="index-pic" v-if="formatIcn(emotion_mean,'emotion')==2">
                            <img src="../assets/images/emotion3.png" alt="" class="index-pic" v-if="formatIcn(emotion_mean,'emotion')==1">
                        </div>                                                
                        <div class="hour-all" v-if="emotion_mean!='0'">                            
                            <img :src="require('../assets/images/'+item+'_r.png')" alt="" v-for="(item,index) in emotion_mean" :key="index+'r'" v-show="emotion_mean>=0&&emotion_mean<=49">
                            <img :src="require('../assets/images/'+item+'_h.png')" alt="" v-for="(item,index) in emotion_mean" :key="index+'h'" v-show="emotion_mean>=50&&emotion_mean<=69">
                            <img :src="require('../assets/images/'+item+'.png')" alt="" v-for="(item,index) in emotion_mean" :key="index" v-show="emotion_mean>=70&&emotion_mean<=100">
                        </div>
                        <div class="hour-nodata" v-else>暂无数据</div>                        
                    </div>
                    <div class="hour-item">
                        <div class="hour-title">总体压力指数</div>
                        <div class="icn-container" v-if="stress_mean!='0'">
                             <img src="../assets/images/stress1.png" alt="" class="index-pic" v-if="formatIcn(stress_mean,'emotion')==1">
                            <img src="../assets/images/stress2.png" alt="" class="index-pic" v-if="formatIcn(stress_mean,'emotion')==2">
                            <img src="../assets/images/stress3.png" alt="" class="index-pic" v-if="formatIcn(stress_mean,'emotion')==3">
                        </div>
                        <div class="hour-all" v-if="stress_mean!='0'">                           
                            <img :src="require('../assets/images/'+item+'.png')" alt="" v-for="(item,index) in stress_mean" :key="index" v-show="stress_mean>=0&&stress_mean<=49">
                            <img :src="require('../assets/images/'+item+'_h.png')" alt="" v-for="(item,index) in stress_mean" :key="index+'h'" v-show="stress_mean>=50&&stress_mean<=69">
                            <img :src="require('../assets/images/'+item+'_r.png')" alt="" v-for="(item,index) in stress_mean" :key="index+'r'" v-show="stress_mean>=70&&stress_mean<=100">
                        </div>
                        <div class="hour-nodata" v-else>暂无数据</div>                        
                    </div>
                    <div class="hour-item">
                        <div class="hour-title">总体疲劳指数</div>
                        <div class="icn-container" v-if="tired_mean!='0'">
                            <img src="../assets/images/tired1.png" alt="" class="index-pic" v-if="formatIcn(tired_mean,'emotion')==1">
                            <img src="../assets/images/tired2.png" alt="" class="index-pic" v-if="formatIcn(tired_mean,'emotion')==2">
                            <img src="../assets/images/tired3.png" alt="" class="index-pic" v-if="formatIcn(tired_mean,'emotion')==3">
                        </div>
                        <div class="hour-all" v-if="tired_mean!='0'">                            
                            <img :src="require('../assets/images/'+item+'.png')" alt="" v-for="(item,index) in tired_mean" :key="index" v-show="tired_mean>=0&&tired_mean<=49">
                            <img :src="require('../assets/images/'+item+'_h.png')" alt="" v-for="(item,index) in tired_mean" :key="index+'h'" v-show="tired_mean>=50&&tired_mean<=69">
                            <img :src="require('../assets/images/'+item+'_r.png')" alt="" v-for="(item,index) in tired_mean" :key="index+'r'" v-show="tired_mean>=70&&tired_mean<=100">
                        </div>     
                        <div class="hour-nodata" v-else>暂无数据</div>                  
                    </div>
                </div>
                <div class="warning-list">
                    <div class="warning-item">
                        <div class="warning-title">特殊情绪</div>
                        <div class="table-h">
                            <div class="h-item col-b">姓名</div>
                            <div class="h-item col-a">情绪</div>
                            <div class="h-item col-c">时间</div>
                        </div>
                        <div class="table-main" @mouseenter="stopEmotion" @mouseleave="startEmotion">
                            <div class="table-body" :style="{top:emotionNum+'vh'}">
                                <div class="body-box"></div>
                                <div :class="index%2==0 ? 'table-r-ac':'table-r'" v-for="(item,index) in emotionWarning" :key="index">
                                    <div class="r-item col-b">{{formatName(item.nickname)}}</div>
                                    <div class="r-item col-a num-color">
                                        <div class="r-num">{{item.warningNum}}</div>
                                        <div class="r-color"></div>
                                    </div>
                                    <div class="r-item col-c">{{item.time}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="warning-item">
                        <div class="warning-title">特殊压力</div>
                        <div class="table-h">
                            <div class="h-item col-b">姓名</div>
                            <div class="h-item col-a">压力</div>
                            <div class="h-item col-c">时间</div>
                        </div>
                        <div class="table-main" @mouseenter="stopStress" @mouseleave="startStress">
                            <div class="table-body" :style="{top:stressNum+'vh'}">
                                <div class="body-box">
                                    <div :class="index%2==0 ? 'table-r-ac':'table-r'" v-for="(item,index) in stressWarning" :key="index">
                                        <div class="r-item col-b">{{formatName(item.nickname)}}</div>
                                        <div class="r-item col-a num-color">
                                            <div class="r-num">{{item.warningNum}}</div>
                                            <div class="r-color"></div>
                                        </div>
                                        <div class="r-item col-c">{{item.time}}</div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div class="warning-item">
                        <div class="warning-title">特殊疲劳</div>
                        <div class="table-h">
                            <div class="h-item col-b">姓名</div>
                            <div class="h-item col-a">疲劳</div>
                            <div class="h-item col-c">时间</div>
                        </div>
                        <div class="table-main">
                            <div class="table-body">
                                <div class="body-box">
                                    <div :class="index%2==0 ? 'table-r-ac':'table-r'" v-for="(item,index) in tiredWarning" :key="index">
                                        <div class="r-item col-b">{{formatName(item.nickname)}}</div>
                                        <div class="r-item col-a num-color">
                                            <div class="r-num">{{item.warningNum}}</div>
                                            <div class="r-color"></div>
                                        </div>
                                        <div class="r-item col-c">{{item.time}}</div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="record-view">
                <div class="online">
                    <div class="online-title">当前在线人数</div>
                    <div class="online-num">
                        <img :src="require('../assets/images/'+item+'.png')" alt="" v-for="(item,index) in onlineNum" :key="index">
                    </div>
                </div>
                <div class="archives">
                    <div class="archives-title">个人心理档案</div>
                    <div class="archives-h">
                        <div class="head-item archives-name">姓名</div>
                        <div class="head-item archives-index">情绪</div>
                        <div class="head-item archives-index">压力</div>
                        <div class="head-item archives-index">疲劳</div>
						<div class="head-item archives-index">步数</div>
						<div class="head-item archives-index">心率</div>
						<div class="head-item archives-index">血氧</div>
                    </div>
                    <div class="archives-main" @mouseenter="stopArchives" @mouseleave="startArchives">
                        <div class="archives-body" :style="{top:archivesNum+'vh'}">
                            <div class="body-box">
                                <div :class="index%2==0 ? 'archives-r-ac':'archives-r'" v-for="(item,index) in hourAll" :key="index">
                                    <div class="archives-item archives-name">{{formatName(item.nickname)}}</div>
                                    <!-- <div class="archives-item">{{item.gender}}</div>
                                    <div class="archives-item">{{formatBirthday(item.birthday)}}</div> -->
                                    <div class="archives-item archives-index">
                                        <div class="item-num">{{item.emotion_mean}}</div>
                                        <div class="item-color" :style="formatIndex(item.emotion_mean,'emotion')"></div>
                                    </div>
                                    <div class="archives-item archives-index">
                                        <div class="item-num">{{item.stress_mean}}</div>
                                        <div class="item-color" :style="formatIndex(item.stress_mean,'stress')"></div>
                                    </div>
                                    <div class="archives-item archives-index">
                                        <div class="item-num">{{item.tired_mean}}</div>
                                        <div class="item-color" :style="formatIndex(item.tired_mean,'tired')"></div>
                                    </div>
									<div class="archives-item archives-index">{{item.pedometer}}</div>
									<div class="archives-item archives-index">{{item.heartRate}}</div>
									<div class="archives-item archives-index">{{item.oxygen}}%</div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
	overall,
} from '../api/index'
import moment from 'moment'
import {all_hour_mean,hour_warning,hour_index} from '../api/index'
import {
	equipment_list
} from '../api/equipment'
import baseUrl from "../utils/baseUrl.js"
import {
	getUser,
	getOverall
} from "../api/visualiztion.js"
export default {
    data() {
        return {
            company:'',
            today:'',
            week:'',
            branch_level:'',
            branch_content:'',
            emotion_chart:'',
            tired_chart:'',
            stress_chart:'',
            emotionWarning:[],
            tiredWarning:[],
            stressWarning:[],
            hourAll:[],
            onlineNum:'',
            warningNum:'0',
            disposeNum:'0',
            no_disposeNum:'0',
            archivesNum:0,
            archivesTimer:null,
            emotionNum:0,
            stressNum:0,
            tiredNum:0,
            emotionTimer:null,
            stressTimer:null,
            tiredTimer:null,
            timer:null,
            emotion_identify:'',
            stress_identify:'',
            tired_identify:'',
            emotionData:[],
            stressData:[],
            tiredData:[],
            emotion_mean:'0',
            stress_mean:'0',
            tired_mean:'0',
        };
    },
    computed: {},
    methods: {
		//获取总体指数
		overAll() {
			overall(this.company, this.branch_content, this.branch_level).then(res => {
				if (res.status == 400) {
					return this.$message.error('暂无数据')
				}
				if (res.status != 200) {
					return this.$message.error('获取失败')
				}
				this.emotion_mean = res.data.emotion_mean+'';
				this.tired_mean = res.data.tired_mean+'';
				this.stress_mean = res.data.stress_mean+'';
			})
		},
		equipmentList() {
			let branchs = this.branch_content.split('|')
			equipment_list(this.company, '', branchs[0]||'',branchs[1]||'', 1, 1000).then(res => {
				if (res.status != 200) {
					return this.$message.error('获取人员列表失败')
				}
				let line = 0;
				for (let i of res.data.equipmentData) {
					if (i.is_online == 'online') {
						line += 1;
					}
				}
				this.onlineNum += line+'';
			})
		},
        goIndex(){
            this.$router.push("/index");
        },
        formatIndex(index,type){
            if(type=='emotion'){
                if(index>=0&&index<=49){                
                    return {backgroundColor:'#F24C4C'}
                }
                if(index>=50&&index<=69){
                    return {backgroundColor:'#E8B235'}
                }
                if(index>=70&&index<=100){
                    return {backgroundColor:'#39D7CA'}
                }
            }
            if(type=='tired'){
                if(index>=0&&index<=49){                
                    return {backgroundColor:'#39D7CA'}
                }
                if(index>=50&&index<=69){
                    return {backgroundColor:'#E8B235'}
                }
                if(index>=70&&index<=100){
                    return {backgroundColor:'#F24C4C'}
                }
            }
            if(type=='stress'){
                if(index>=0&&index<=49){                
                    return {backgroundColor:'#39D7CA'}
                }
                if(index>=50&&index<=69){
                    return {backgroundColor:'#E8B235'}
                }
                if(index>=70&&index<=100){
                    return {backgroundColor:'#F24C4C'}
                }
            }
        },
        formatBirthday(timeStr){
            let timestamp=moment(timeStr+' 00:00:00')
            let nowTime=moment().valueOf()
            let birthday=moment(timestamp).valueOf()
            let nowYear=new Date(nowTime).getFullYear()
            let birthdayYear=new Date(birthday).getFullYear()
            return parseInt(nowYear-birthdayYear)
        },
        formatName(name){
            if(name.length==2){
                return '*'+name[1]
            }
            if(name.length==3){
                return '*'+name[1]+'*'
            } 
            if(name.length>=4){
                return '*'+name[1]+'**'
            }else{
				return name;
			}
        },
        formatIcn(index,type){
            if(type=='emotion'){
                if(index>=0&&index<=49){                
                    return 1
                }
                if(index>=50&&index<=69){
                    return 2
                }
                if(index>=70&&index<=100){
                    return 3
                }
            }
            if(type=='tired'){
                if(index>=0&&index<=49){                
                    return 1
                }
                if(index>=50&&index<=69){
                    return 2
                }
                if(index>=70&&index<=100){
                    return 3
                }
            }
            if(type=='stress'){
                if(index>=0&&index<=49){                
                    return 1
                }
                if(index>=50&&index<=69){
                    return 2
                }
                if(index>=70&&index<=100){
                    return 3
                }
            }
        },
        allHourMean(){
            all_hour_mean(this.company,this.branch_content,this.branch_level).then(res=>{
                if(res.status!=200){
                    return this.$message.error('获取日心情指数失败')
                }
                this.emotionAllHour=res.data.emotionAllHour;
                this.stressAllHour=res.data.stressAllHour;
                this.tiredAllHour=res.data.tiredAllHour;
				let emo = [{value:0,name:'愉悦',seriesName:"",percent:10},{value:0,name:'一般',seriesName:"",percent:10},{value:0,name:'较差',seriesName:"",percent:10}]
				for(let i in this.emotionAllHour){
					if(this.emotionAllHour && this.emotionAllHour[i]>=70){
						emo[0].value+=1;
					}else if(this.emotionAllHour && this.emotionAllHour[i]>=50){
						emo[1].value+=1;
					}else if(this.emotionAllHour && this.emotionAllHour[i] != null && this.emotionAllHour[i]>=0){
						emo[2].value+=1;
					}
				}
				this.emotionData = emo;
				
				let str = [{value:0,name:'压力较大',seriesName:"",percent:10},{value:0,name:'稍有压力',seriesName:"",percent:10},{value:0,name:'放松',seriesName:"",percent:10}]
				for(let i in this.stressAllHour){
					if(this.stressAllHour && this.stressAllHour[i]>=70){
						str[0].value+=1;
					}else if(this.stressAllHour && this.stressAllHour[i]>=50){
						str[1].value+=1;
					}else if(this.stressAllHour && this.stressAllHour[i] != null && this.stressAllHour[i]>=0){
						str[2].value+=1;
					}
				}
				this.stressData = str;
				
				let tir = [{value:0,name:'感到劳累',seriesName:"",percent:10},{value:0,name:'有点疲惫',seriesName:"",percent:10},{value:0,name:'精力充沛',seriesName:"",percent:10}]
				for(let i in this.tiredAllHour){
					if(this.tiredAllHour && this.tiredAllHour[i]>=70){
						tir[0].value+=1;
					}else if(this.tiredAllHour && this.tiredAllHour[i]>=50){
						tir[1].value+=1;
					}else if(this.tiredAllHour && this.tiredAllHour[i] != null &&  this.tiredAllHour[i]>=0){
						tir[2].value+=1;
					}
				}
				this.tiredData = tir;
                this.emotionChart();
                this.tiredChart();
                this.stressChart();
				this.emotionIdentify()
				this.stressIdentify()
				this.tiredIdentify()
            })
        },
        emotionChart(){
            let windowW=window.screen.width;
            let Size=12;
            if(windowW>2400){
                Size=parseInt(windowW/140)
            }
            let chartDom=document.getElementById('emotion-chart') 
            if(this.emotion_chart!=''){
                this.emotion_chart.dispose()
            }
            this.emotion_chart = this.$echarts.init(chartDom);
            let emotionAllHour=this.emotionAllHour;
            let option = {
                tooltip: {
                    trigger: 'axis',
                    formatter:(params)=>{
                        let str='时间:'+params[0].dataIndex+'时<br/>'+params[0].seriesName+':'+params[0].data;
                        return str
                    },
                    textStyle:{
                        fontSize:Size
                    }
                },
                grid: {
                    left: '12%',
                    right: '12%',
                    top:'20%',
                    bottom: '15%',
                },
                xAxis: {
                    type: 'category',
                    data: ['0', '1', '2', '3', '4', '5', '6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#0e1a62",
                        }
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#6e73ab",
                            fontSize:Size+'px'
                        }
                    },
                    splitLine: {
                        show:true,
                        lineStyle: {
                            color: "#0e1a62",
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    max:100,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#0e1a62",
                            width: 1,
                            type: "solid"
                        }
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#6e73ab",
                            fontSize:Size+'px'
                        }
                    },
                    splitLine: {
                        show:true,
                        lineStyle: {
                            color: "#0e1a62",
                        }
                    },                    
                },
                series: [
                    {
                        name:'心情指数',
                        data: emotionAllHour,
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        itemStyle : {  
                            normal : {                                 
                                lineStyle:{  
                                    color:'#04f0f2',
                                    width:Size*0.2  
                                }  
                            }  
                        },  
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#1501db'
                                },
                                {
                                    offset: 1,
                                    color: '#01004e'
                                }
                            ])
                        }
                    }
                ]
            };
            this.emotion_chart.clear()
            this.emotion_chart.setOption(option);
        },
        tiredChart(){
            let windowW=window.screen.width;
            let Size=12;
            if(windowW>2400){
                Size=parseInt(windowW/140)
            }
            let chartDom=document.getElementById('tired-chart') 
            if(this.tired_chart!=''){
                this.tired_chart.dispose()
            }
            this.tired_chart = this.$echarts.init(chartDom);
            let tiredAllHour=this.tiredAllHour;
            let option = {
                tooltip: {
                    trigger: 'axis',
                    formatter:(params)=>{
                        let str='时间:'+params[0].dataIndex+'时<br/>'+params[0].seriesName+':'+params[0].data;
                        return str
                    },
                    textStyle:{
                        fontSize:Size
                    }
                },
                grid: {
                    left: '12%',
                    right: '12%',
                    top:'20%',
                    bottom: '15%',
                },
                xAxis: {
                    type: 'category',
                    data: ['0', '1', '2', '3', '4', '5', '6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#0e1a62",
                        }
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#6e73ab",
                            fontSize:Size+'px'
                        }
                    },
                    splitLine: {
                        show:true,
                        lineStyle: {
                            color: "#0e1a62",
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    max:100,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#0e1a62",
                            width: 1,
                            type: "solid"
                        }
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#6e73ab",
                            fontSize:Size+'px'
                        }
                    },
                    splitLine: {
                        show:true,
                        lineStyle: {
                            color: "#0e1a62",
                        }
                    },   
                },
                series: [
                    {
                        name:'疲劳指数',
                        data: tiredAllHour,
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        itemStyle : {  
                            normal : {                                 
                                lineStyle:{  
                                    color:'#04f0f2',
                                    width:Size*0.2    
                                }  
                            }  
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#1501db'
                                },
                                {
                                    offset: 1,
                                    color: '#01004e'
                                }
                            ])
                        }  
                    }
                ]
            };
            this.tired_chart.clear()
            this.tired_chart.setOption(option);
        },
        stressChart(){
            let windowW=window.screen.width;
            let Size=12;
            if(windowW>2400){
                Size=parseInt(windowW/140)
            }
            let chartDom=document.getElementById('stress-chart') 
            if(this.stress_chart!=''){
                this.stress_chart.dispose()
            }
            this.stress_chart = this.$echarts.init(chartDom);
            let stressAllHour=this.stressAllHour;
            let option = {
                tooltip: {
                    trigger: 'axis',
                    formatter:(params)=>{
                        let str='时间:'+params[0].dataIndex+'时<br/>'+params[0].seriesName+':'+params[0].data;
                        return str
                    },
                    textStyle:{
                        fontSize:Size
                    }
                },
                grid: {
                    left: '12%',
                    right: '12%',
                    top:'20%',
                    bottom: '15%',
                },
                xAxis: {
                    type: 'category',
                    data: ['0', '1', '2', '3', '4', '5', '6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#0e1a62",
                        }
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#6e73ab",
                            fontSize:Size+'px'
                        }
                    },
                    splitLine: {
                        show:true,
                        lineStyle: {
                            color: "#0e1a62",
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    max:100,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#0e1a62",
                            width: 1,
                            type: "solid"
                        }
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#6e73ab",
                            fontSize:Size+'px'
                        }
                    },
                    splitLine: {
                        show:true,
                        lineStyle: {
                            color: "#0e1a62",
                        }
                    },   
                },
                series: [
                    {
                        name:'压力指数',
                        data: stressAllHour,
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        itemStyle : {  
                            normal : {                         
                                lineStyle:{  
                                    color:'#04f0f2',
                                    width:Size*0.2   
                                }  
                            }  
                        },  
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#1501db'
                                },
                                {
                                    offset: 1,
                                    color: '#01004e'
                                }
                            ])
                        }
                    }
                ]
            };
            this.stress_chart.clear()
            this.stress_chart.setOption(option);
        },
        hourWarning(){
            hour_warning(this.company,this.branch_content,this.branch_level).then(res=>{
                if(res.status!=200){
                    return this.$message.error('获取预警失败')
                }
                this.emotionWarning=res.data.emotionWarningSort;
                this.stressWarning=res.data.stressWarningSort;
                this.tiredWarning=res.data.tiredWarningSort;
            })
        },
        hourIndex(){
            hour_index(this.company,this.branch_content,this.branch_level).then(res=>{
                if(res.status==400){
                    return this.$message.error('暂无数据')
                }
                if(res.status!=200){
                    return this.$message.error('获取档案失败')
                }
                this.hourAll=res.data.hourIndex;
                this.onlineNum=res.data.hourIndex.length+''                                
            })
        },
        emotionIdentify(){
            let windowW=window.screen.width;
            let legendW=12;
            let legendH=12;
            if(windowW>2400){
                legendW=parseInt(windowW/120)
                legendH=parseInt(windowW/120)
            }
            let chartDom=document.getElementById('identify-emotion') 
            if(this.emotion_identify!=''){
                this.emotion_identify.dispose()
            }
            this.emotion_identify = this.$echarts.init(chartDom);
            let emotionData=this.emotionData;
			// let emotionData=[{value:20,name:'较差',seriesName:"213",percent:10},{value:80,name:'愉悦',seriesName:"213",percent:10}];
            let colorList=[];
            for(let item of emotionData){
                if(item.name=='较差'){
                    colorList.push('#F24C4C')
                }
                if(item.name=='一般'){
                    colorList.push('#E8B235')
                }
                if(item.name=='愉悦'){
                    colorList.push('#39D7CA')
                }
            }            
            let option = {
                tooltip: {
                    trigger: 'item',
					formatter:'{a} <br/>{b} : {c} ({d}%)',
                    // formatter:(params)=>{
                    //     let str=params.seriesName+'<br/>百分比:{d}'+params.percent+'%<br/>'+params.name+':'+params.value;
                    //     return str
                    // },
                    textStyle:{
                        fontSize:legendH*0.8
                    }
                },
                legend: {
                    bottom: '5%',
                    left: 'center',
                    itemHeight:legendW,
                    itemWidth:legendH,
                    textStyle:{
                        color:'#19ECFF',
                        fontSize:legendH*0.8,
                    }
                },
                series: [
                    {
                        name: '情绪占比',
                        type: 'pie',
                        radius: ['40%', '55%'],
                        avoidLabelOverlap: false,
                        itemStyle: {                            
                            // borderColor: '#fff',
                            // borderWidth: 2
                            normal:{
                                borderRadius: legendW/2,
                                color:function(colors){
                                    return colorList[colors.dataIndex]
                                }
                            },                           
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: emotionData,
                    }
                ]
            };
            this.emotion_identify.clear()
            this.emotion_identify.setOption(option);
        },
        stressIdentify(){
            let windowW=window.screen.width;
            let legendW=12;
            let legendH=12;
            if(windowW>2400){
                legendW=parseInt(windowW/120)
                legendH=parseInt(windowW/120)
            }
            let chartDom=document.getElementById('identify-stress') 
            if(this.stress_identify!=''){
                this.stress_identify.dispose()
            }
            this.stress_identify = this.$echarts.init(chartDom);
            let stressData=this.stressData;
            let colorList=[]
            for(let item of stressData){
                if(item.name=='压力较大'){
                    colorList.push('#F24C4C')
                }
                if(item.name=='稍有压力'){
                    colorList.push('#E8B235')
                }
                if(item.name=='放松'){
                    colorList.push('#39D7CA')
                }
            }            
            let option = {
                tooltip: {
                    trigger: 'item',
					formatter:'{a} <br/>{b} : {c} ({d}%)',
                    // formatter:(params)=>{
                    //     let str=params.seriesName+'<br/>百分比:'+params.percent+'%<br/>'+params.name+':'+params.value;
                    //     return str
                    // },
                    textStyle:{
                        fontSize:legendH*0.8
                    }
                },
                legend: {
                    bottom: '5%',
                    left: 'center',
                    itemHeight:legendW,
                    itemWidth:legendH,
                    textStyle:{
                        color:'#19ECFF',
                        fontSize:legendH*0.8,
                    }
                },
                series: [
                    {
                        name: '压力占比',
                        type: 'pie',
                        radius: ['40%', '55%'],
                        avoidLabelOverlap: false,
                        itemStyle: {                            
                            // borderColor: '#fff',
                            // borderWidth: 2
                            normal:{
                                borderRadius: legendW/2,
                                color:function(colors){
                                    return colorList[colors.dataIndex]
                                }
                            },                           
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: stressData,
                    }
                ]
            };
            this.stress_identify.clear()
            this.stress_identify.setOption(option);
        },
        tiredIdentify(){
            let windowW=window.screen.width;
            let legendW=12;
            let legendH=12;
            if(windowW>2400){
                legendW=parseInt(windowW/120)
                legendH=parseInt(windowW/120)
            }
            let chartDom=document.getElementById('identify-tired') 
            if(this.tired_identify!=''){
                this.tired_identify.dispose()
            }
            this.tired_identify = this.$echarts.init(chartDom);
            let tiredData=this.tiredData;
            let colorList=[]
            for(let item of tiredData){
                if(item.name=='感到劳累'){
                    colorList.push('#F24C4C')
                }
                if(item.name=='有点疲惫'){
                    colorList.push('#E8B235')
                }
                if(item.name=='精力充沛'){
                    colorList.push('#39D7CA')
                }
            }            
            let option = {
                tooltip: {
                    trigger: 'item',
					formatter:'{a} <br/>{b} : {c} ({d}%)',
                    // formatter:(params)=>{
                    //     let str=params.seriesName+'<br/>百分比:{d}'+params.percent+'%<br/>'+params.name+':'+params.value;
                    //     return str
                    // },
                    textStyle:{
                        fontSize:legendH*0.8
                    }
                },
                legend: {
                    bottom: '5%',
                    left: 'center',
                    itemHeight:legendW,
                    itemWidth:legendH,
                    textStyle:{
                        color:'#19ECFF',
                        fontSize:legendH*0.8,
                    }
                },
                series: [
                    {
                        name: '疲劳占比',
                        type: 'pie',
                        radius: ['40%', '55%'],
                        avoidLabelOverlap: false,
                        itemStyle: {                            
                            // borderColor: '#fff',
                            // borderWidth: 2
                            normal:{
                                borderRadius: legendW/2,
                                color:function(colors){
                                    return colorList[colors.dataIndex]
                                }
                            },                           
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: tiredData,
                    }
                ]
            };
            this.tired_identify.clear()
            this.tired_identify.setOption(option);
        },
        emotionClassify(){
            // emotion_classify(this.company,this.branch_content,this.branch_level).then(res=>{
            //     if(res.status==200){
            //         this.emotionData=res.data.emotionData;
            //         this.emotionIdentify()
            //         this.stressData=res.data.stressData;
            //         this.stressIdentify()
            //         this.tiredData=res.data.tiredData;
            //         this.tiredIdentify()
            //     }
            // })
        },
        hourIdentify(){
            // hour_identify_all(this.company,this.branch_content,this.branch_level).then(res=>{
            //     if(res.status==200){
            //         if(res.data.emotion_mean!=-1){
            //             this.emotion_mean=res.data.emotion_mean+'';
            //         }
            //         if(res.data.stress_mean!=-1){
            //             this.stress_mean=res.data.stress_mean+'';
            //         }                    
            //         if(res.data.tired_mean!=-1){
            //             this.tired_mean=res.data.tired_mean+'';
            //         }                    
            //     }
            // })
        },   
        stopArchives(){
            if(this.hourAll.length>15){
                clearInterval(this.archivesTimer)
                this.archivesTimer=null;
            }            
        },
        startArchives(){
            if(this.hourAll.length>15){
                this.archivesTimer=setInterval(()=>{
                    this.archivesNum=this.archivesNum-4;
                    if(this.archivesNum<this.hourAll.length*-4){
                        this.archivesNum=0                
                    }
                },1000)
            }           
        },
        stopEmotion(){
            if(this.emotionWarning.length>3){
                clearInterval(this.emotionTimer)
                this.emotionTimer=null;
            }            
        },
        startEmotion(){
            if(this.emotionWarning.length>3){
                this.emotionTimer=setInterval(()=>{
                    this.emotionNum=this.emotionNum-4;
                    if(this.emotionNum<this.emotionWarning.length*-4){
                        this.emotionNum=0                
                    }
                },1000)
            }           
        },     
        stopStress(){
            if(this.stressWarning.length>3){
                clearInterval(this.stressTimer)
                this.stressTimer=null;
            }            
        },
        startStress(){
            if(this.stressWarning.length>3){
                this.stressTimer=setInterval(()=>{
                    this.stressNum=this.stressNum-4;
                    if(this.stressNum<this.stressWarning.length*-4){
                        this.stressNum=0                
                    }
                },1000)
            }           
        },   
        stopTired(){
            if(this.stressWarning.length>3){
                clearInterval(this.stressTimer)
                this.stressTimer=null;
            }            
        },
        startTired(){
            if(this.tiredWarning.length>3){
                this.tiredTimer=setInterval(()=>{
                    this.tiredNum=this.tiredNum-4;
                    if(this.tiredNum<this.tiredWarning.length*-4){
                        this.tiredNum=0                
                    }
                },1000)
            }           
        },  
        identifyAll(){
            // identify_all(this.company,this.branch_content,this.branch_level).then(res=>{
            //     if(res.status!=200){
            //         return this.$message.error('暂无数据！')
            //     }
            //     this.emotionAllHour=res.data.emotionAllHour;
            //     this.stressAllHour=res.data.stressAllHour;
            //     this.tiredAllHour=res.data.tiredAllHour;
            //     this.emotionChart();
            //     this.tiredChart();
            //     this.stressChart();
            //     this.emotionWarning=res.data.emotionWarning;
            //     this.stressWarning=res.data.stressWarning;
            //     this.tiredWarning=res.data.tiredWarning;
            //     this.hourAll=res.data.hourEveryIndex;
            //     this.onlineNum=res.data.hourEveryIndex.length+'';
            //     this.emotionData=res.data.emotionClassifyData;
            //     this.emotionIdentify()
            //     this.stressData=res.data.stressClassifyData;
            //     this.stressIdentify()
            //     this.tiredData=res.data.tiredClassifyData;
            //     this.tiredIdentify()
            //     if(res.data.hourEmotionMean!=-1){
            //         this.emotion_mean=res.data.hourEmotionMean+'';
            //     }
            //     if(res.data.hourStressMean!=-1){
            //         this.stress_mean=res.data.hourStressMean+'';
            //     }                    
            //     if(res.data.hourTiredMean!=-1){
            //         this.tired_mean=res.data.hourTiredMean+'';
            //     }        
            // })
        }             
    },
    created() {
        this.company=sessionStorage.getItem('company');
        this.branch_level=sessionStorage.getItem('level');
        this.branch_content=sessionStorage.getItem('branch-content');
        let week=moment().day();
        this.today=moment().format("YYYY-MM-DD HH:mm:ss")
        setInterval(()=>{
            this.today=moment().format("YYYY-MM-DD HH:mm:ss")
        },1000)
        switch (week) {
            case 0: {
                this.week='星期日';
                break;
            }
            case 1: {
                this.week='星期一';
                break;
            }
            case 2: {
                this.week='星期二';
                break;
            }
            case 3: {
                this.week='星期三';
                break;
            }
            case 4: {
                this.week='星期四';
                break;
            }
            case 5: {
                this.week='星期五';
                break;
            }
            case 6: {
                this.week='星期六';
                break;
            }
        }
		this.equipmentList()
		this.overAll()
        this.allHourMean()
        this.hourWarning()
        this.hourIndex()    
        // this.hourIdentify()   
        this.identifyAll()     
    },
    mounted(){
        //this.emotionClassify()
        if(this.hourAll.length>15){
            this.archivesTimer=setInterval(()=>{
                this.archivesNum=this.archivesNum-4;
                if(this.archivesNum<this.hourAll.length*-4){
                    this.archivesNum=0                
                }
            },1000)
        } 
        if(this.emotionWarning.length>3){
            this.emotionTimer=setInterval(()=>{
                this.emotionNum=this.emotionNum-4;
                if(this.emotionNum<this.emotionWarning.length*-4){
                    this.emotionNum=0                
                }
            },1000)
        }     
        if(this.stressWarning.length>3){
            this.stressTimer=setInterval(()=>{
                this.stressNum=this.stressNum-4;
                if(this.stressNum<this.stressWarning.length*-4){
                    this.stressNum=0                
                }
            },1000)
        }    
        if(this.tiredWarning.length>3){
            this.tiredTimer=setInterval(()=>{
                this.tiredNum=this.tiredNum-4;
                if(this.tiredNum<this.tiredWarning.length*-4){
                    this.tiredNum=0                
                }
            },1000)
        }
        this.timer=setInterval(()=>{
            // this.emotionClassify()
            // this.hourIndex()
            // this.allHourMean()
            // this.hourWarning()
            // this.hourIdentify()
            this.identifyAll() 
        },60000)       
    },
    beforeDestroy(){
        clearInterval(this.timer)
        clearInterval(this.archivesTimer)
        clearInterval(this.emotionTimer)
        clearInterval(this.stressTimer)
        clearInterval(this.tiredTimer)
    }
};
</script>
<style lang='less' scoped>
.container{
    height: 100vh;
    background-color: #01004e;
}
.header{
    height: 12vh;
    position: relative;
    background: url('../assets/images/header-bg.png') no-repeat;
    background-size: 100% 100%;
}
.page-title{
    height: 8vh;
    line-height: 8vh;
    text-align: center;
    font-size: 0.36rem;
    font-weight: 900;
    color: #fff;
}
.date-time{
    height: 12vh;
    line-height: 12vh;
    font-size: 0.16rem;
    color: #32C5FF;
    position: absolute;
    top: 0px;
    left: 9vw;
}
.index{
    height: 12vh;
    line-height: 12vh;
    font-size: 0.16rem;
    color: #32C5FF;
    position: absolute;
    top: 0px;
    right: 11vw;
}
.week{
    position: absolute;    
    left: 19vw;
    top: 7.8vh;
    height: 1.4vh;
    line-height: 1.4vh;
    font-size: 0.12rem;
    font-weight: bold;
    color: #32C5FF;
}
.main{
    padding: 1.5vh 2vw;
    display: flex;
    justify-content: space-between;
}
.index-day{
    width: 23vw;    
}
.index-box{
    width: 23vw;
    height: 27vh;
    margin-bottom: 1vh;
    background: url('../assets/images/index-bg.png') no-repeat;
    background-size: 100% 100%;
}
.index-title{
    height: 3.6vh;
    line-height: 3.6vh;
    font-size: 0.14rem;
    font-weight: bold;
    color: #19ECFF;
    text-align: center;
}
.chart-box{
    width: 100%;
    height:20vh;
    margin-top: 3vh;
}
.chart{
    height: 100%;
    width: 100%;
}
.view-mid{
   width: 48vw; 
}
.identify{
    width: 48vw;
    // height: 37vh;
    height: 28vh;
    display: flex;
    justify-content: space-between;
}
.identify-item{
    width: 32%;
    height: 27vh;
    background: url(/img/warning-bg.c10f5c40.png) no-repeat;
    background-size: 100% 100%;
}
.identify-title{
    height: 3vh;
    line-height: 3vh;
    font-size: 0.14rem;
    font-weight: bold;
    color: #19ECFF;
    text-align: center;
    margin-bottom: 1vh;
}
.identify-chart{
    width: 100%;
    height: 20vh;
}
#identify-emotion ,#identify-stress ,#identify-tired{
    width: 100%;
    height: 100%;
}
.index-hour{
    width: 48vw;
    height: 27vh;
    display: flex;
    justify-content: space-between;
}
.hour-item{
    width: 32%;
    height: 27vh;
    background: url(/img/warning-bg.c10f5c40.png) no-repeat;
    background-size: 100% 100%;
}
.hour-title{
    height: 3vh;
    line-height: 3vh;
    font-size: 0.14rem;
    font-weight: bold;
    color: #19ECFF;
    text-align: center;
    margin-bottom: 1vh;
}
.icn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;
}
.index-pic{
    height: 6vh;
}
.hour-all{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
}
.hour-all img{
    height: 6vh;
}
.hour-nodata{
    height: 20vh;
    line-height: 20vh;
    font-weight: 500;
    font-size: 0.14rem;
    color: #19ECFF;
    text-align: center;
}
.warning-list{
    width: 48vw;
    display: flex;
    justify-content: space-between;
    margin-top: 1vh;
}
.warning-item{
    height: 27vh;
    width: 15.5vw;
    background: url('../assets/images/warning-bg.png') no-repeat;
    background-size: 100% 100%;
}
.warning-title{
    height: 3vh;
    line-height: 3vh;
    font-size: 0.14rem;
    font-weight: bold;
    color: #19ECFF;
    text-align: center;
    margin-bottom: 1vh;
}
.table-h{
    height: 4vh;
    display: flex;
    justify-content: flex-start;
    padding: 0px 0.3vw;
}
.h-item{
    height: 4vh;
    line-height: 4vh;
    width: 25%;
    text-align: center;
    font-size: 0.14rem;
    color: #fff;
}
.table-main{
    height: 13vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.table-body{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
}
.table-r{
    height: 4vh;
    display: flex;
    justify-content: flex-start;
}
.table-r-ac{
    height: 4vh;
    display: flex;
    justify-content: flex-start;
    background-color: #051471;
}
.r-item{
    height: 4vh;
    line-height: 4vh;
    width: 25%;
    text-align: center;
    font-size: 0.14rem;
    color: #fff;
}
.num-color{
    display: flex;
    justify-content: center;
}
.r-num{
    height: 4vh;
    line-height: 4vh;
    font-size: 0.14rem;
    color: #fff;
    margin-right: 0.2vw;
}
.r-color{
    height: 1vh;
    width: 1vh;
    border-radius: 50%;
    background-color: #F24C4C;
    margin-top: 1.5vh;    
}
.record-view{
    width: 23vw;
}
.online{
    height: 14vh;
    background: url('../assets/images/online-bg.png') no-repeat;
    background-size: 100% 100%;
}
.online-title{
    height: 3.5vh;
    line-height: 3.5vh;
    font-size: 0.14rem;
    font-weight: bold;
    color: #19ECFF;
    text-align: center;
    margin-bottom: 1vh;
}
.online-num{
    display: flex;
    justify-content: center;
    margin-top: 3vh;
}
.online-num img{
    height: 5vh;
    width: 5vh;
    margin: 0px 0.1vw;
}
.archives{
    height: 68vh;
    margin-top: 1vh;
    background: url('../assets/images/archives-bg.png') no-repeat;
    background-size: 100% 100%;
}
.archives-title{
    height: 3.5vh;
    line-height: 3.5vh;
    font-size: 0.14rem;
    font-weight: bold;
    color: #19ECFF;
    text-align: center;
    margin-bottom: 1vh;
}
.archives-h{
    height: 4vh;
    display: flex;
    justify-content: flex-start;
    padding: 0px 0.3vw;
}
.head-item{
    height: 4vh;
    line-height: 4vh;
    width: 10%;
    text-align: center;
    font-size: 0.14rem;
    color: #fff;
}
.archives-main{
    height: 60vh;
    position: relative;
    overflow: hidden;    
}
.archives-body{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}
.body-box{
    padding: 0px 0.3vw;
}
.archives-r{
    display: flex;
    justify-content: flex-start;
}
.archives-r-ac{
    display: flex;
    justify-content: flex-start;
    background-color: #051471;
}
.archives-item{
    height: 4vh;
    line-height: 4vh;
    width: 10%;
    text-align: center;
    font-size: 0.14rem;
    color: #fff;
}
.archives-name{
    width: 40%;
}
.archives-index{
    width: 20%;
    display: flex;
    justify-content: center;
}
.item-num{
    height: 4vh;
    line-height: 4vh;
    font-size: 0.14rem;
    color: #fff;
    margin-left: 0.5vw;
}
.item-color{
    height: 1vh;
    width: 1vh;
    border-radius: 50%;
    background-color: #F24C4C;
    margin-top: 1.5vh;
    margin-left: 0.1vw;
}
.col-a{
    width: 20%;
}
.col-b{
    width: 30%;
}
.col-c{
    width: 50%;
}
</style>